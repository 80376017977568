import React, { FC, useEffect } from 'react'
import { Button, Modal } from '@material-ui/core'
import clsx from 'clsx'
import messages from '@utils/messages'
import cartMessages from '@src/routes/Cart/messages'
import { FormattedMessage, useIntl } from 'react-intl'
import { makeStyles, Theme } from '@material-ui/core/styles'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useAddToCart, useFullCartLazy } from '@src/utils/useCart'
import { CartItemInput, CartRequestAction } from '@src/types/graphql-types'
import { useRouter, cartRoute, CartType } from '@src/routes'
import { sendBuyItNowClickEvent } from '@src/utils/analytics'
import { BuyItNowEventActionEnum } from '@utils/analytics/enums'
import { useCurrentUser } from '@utils/useCurrentUser'
import { extractData, OriginalError } from '@utils/errorHandler'
import BuyNowModal from '../BuyNowModal'
import CircleLoader from '@src/components/CircleLoader'
import { useGlobalModal } from '@src/utils/useGlobalModal'
import BuyNowModalB2B from '../BuyNowModal/BuyNowModalB2B'
import {
  useGlobalSnackbar,
  SnackbarType,
} from '@src/components/GlobalSnackbar/globalSnackBarContext'
import { ModalCloseReason } from '../ResponsiveModal/ResponsiveModal'

const useStyles = makeStyles((theme: Theme) => ({
  buyNowButton: {
    backgroundColor: theme.palette.common.white,
    minWidth: 75,
  },
  buyNowButtonMobile: {
    minHeight: 48,
  },
}))

type buttonSize = 'small' | 'medium' | 'large'
interface BuyNowButtonProps {
  materialNumber?: string
  quantity?: number
  size?: buttonSize
  fullWidth?: boolean
  onSuccess?: () => void
  items?: CartItemInput[]
  marketplace?: boolean
  disabled?: boolean
  gaEcommerceEvent?: () => void
}

const BuyNowButton: FC<BuyNowButtonProps> = ({
  materialNumber,
  quantity,
  size = 'large',
  fullWidth = false,
  onSuccess,
  items,
  marketplace,
  disabled,
  gaEcommerceEvent,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const displayFullWidth = !isDesktop || fullWidth
  const router = useRouter()
  const intl = useIntl()
  const [addToCart] = useAddToCart()
  const { setGlobalSnackbarState } = useGlobalSnackbar()
  const { isB2BUser } = useCurrentUser()
  const { setGlobalModalComponent, clearGlobalModalComponent } =
    useGlobalModal()

  const action = marketplace
    ? CartRequestAction.Marketplacebuyitnowcart
    : CartRequestAction.Buyitnowcart

  const buyItNowText =
    size === 'small' ? { ...messages.BUY_NOW } : { ...messages.BUY_IT_NOW }

  const cartItemsInput = items?.length
    ? items
    : [{ materialNumber: materialNumber ?? '', quantity: quantity ?? 1 }]

  const [
    fetchFullCart,
    { data: cartData, loading: cartLoading, error: cartError },
  ] = useFullCartLazy({
    variables: { id: action },
    fetchPolicy: 'network-only',
  })

  // if cart is eligible for buy now, submit the order
  // otherwise redirect user to step 2 cart
  const handleBuyNowClick = async () => {
    if (gaEcommerceEvent) {
      gaEcommerceEvent()
    }
    await addToCart({
      variables: {
        input: {
          items: cartItemsInput ?? [],
          action,
          marketplace,
        },
      },
    })
    await fetchFullCart()
  }

  useEffect(() => {
    if (!cartData?.getCart) return

    const { headerSAPError, cartEligibleForBuyItNow } = cartData.getCart

    const handleClose = (reason?: string) => {
      let eventAction: BuyItNowEventActionEnum
      if (
        reason === ModalCloseReason.BackdropClick ||
        reason === ModalCloseReason.EscapeKeyDown
      ) {
        eventAction = BuyItNowEventActionEnum.IgnoreBuyItNowModal
      } else {
        eventAction = BuyItNowEventActionEnum.CloseBuyItNowModal
      }

      sendBuyItNowClickEvent(
        items?.length
          ? items?.map((item) => item?.materialNumber)
          : [materialNumber ?? ''],
        eventAction
      )

      clearGlobalModalComponent()
    }
    switch (true) {
      case !!headerSAPError: {
        const errorMessage = headerSAPError?.message
        setGlobalSnackbarState({
          open: true,
          message: errorMessage ?? (
            <FormattedMessage {...cartMessages.TECHNICAL_DIFFICULTIES} />
          ),
          variant: SnackbarType.Error,
        })
        break
      }
      case isB2BUser: {
        setGlobalModalComponent(
          <BuyNowModalB2B
            cart={cartData.getCart}
            onClose={clearGlobalModalComponent}
          />
        )
        if (onSuccess) onSuccess()
        break
      }
      case cartEligibleForBuyItNow: {
        setGlobalModalComponent(
          <BuyNowModal
            cart={cartData.getCart}
            onClose={(reason) => handleClose(reason)}
          />
        )
        if (onSuccess) onSuccess()
        break
      }
      default: {
        const path = cartRoute.checkout(
          marketplace ? CartType.MarketplaceBuyNowCart : CartType.BuyNowCart
        )
        router.push(`${path}&material=${materialNumber}`)
        break
      }
    }
  }, [cartData])

  useEffect(() => {
    if (!cartError) return

    let message = intl.formatMessage(cartMessages.TECHNICAL_DIFFICULTIES)
    const { errors } = extractData(cartError as OriginalError)

    if (!errors[0]?.code) {
      message = intl.formatMessage(messages.GENERIC_ERROR)
    }

    setGlobalSnackbarState({
      open: true,
      message,
      variant: SnackbarType.Error,
    })
  }, [cartError])

  return (
    <>
      {cartLoading && (
        <Modal open hideBackdrop>
          <CircleLoader />
        </Modal>
      )}

      <Button
        className={clsx(classes.buyNowButton, {
          [classes.buyNowButtonMobile]: !isDesktop,
        })}
        color="secondary"
        fullWidth={displayFullWidth}
        onClick={handleBuyNowClick}
        size={size}
        variant="outlined"
        disabled={disabled}
      >
        <FormattedMessage {...buyItNowText} />
      </Button>
    </>
  )
}

export default BuyNowButton
