import React from 'react'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  useMediaQuery,
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import messages from '@utils/messages'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    minWidth: 182,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    margin: theme.spacing(5, 6, 0),
    [theme.breakpoints.down('xs')]: {
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold,
      margin: theme.spacing(5, 4, 0),
    },
  },
  actions: {
    padding: theme.spacing(2, 5, 3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      padding: theme.spacing(2, 4, 3),
    },
  },
  dialogContent: {
    flex: 'none',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 4),
    },
  },
  dialogText: {
    [theme.breakpoints.down('xs')]: {
      color: theme.palette.common.black,
    },
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: theme.spacing(2.5),
    },
  },
  cancelButton: {
    color: theme.palette.grey[600],
    [theme.breakpoints.down('xs')]: {
      color: theme.palette.primary.main,
    },
  },
  closeButton: {
    color: theme.palette.common.black,
    padding: 0,
    height: 'fit-content',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  cancelIcon: {
    fontSize: '2rem',
  },
}))

export interface AlertProps {
  open: boolean
  loading?: boolean
  fullscreen?: boolean
  title?: React.ReactNode
  description?: React.ReactNode
  cancelText?: React.ReactNode
  confirmText?: React.ReactNode
  onConfirm: () => void
  onCancel: () => void
  onDismiss?: () => void
}

export interface CloseIconProps {
  onDismiss?: () => void
}

const CloseIcon: React.FC<CloseIconProps> = ({ onDismiss }) => {
  const classes = useStyles()
  return (
    <IconButton className={classes.closeButton} onClick={onDismiss}>
      <Close className={classes.cancelIcon} />
    </IconButton>
  )
}

const AlertDialog: React.FC<AlertProps> = ({
  open,
  loading,
  title,
  description,
  cancelText = <FormattedMessage {...messages.CANCEL} />,
  confirmText = <FormattedMessage {...messages.AGREE} />,
  onConfirm,
  onCancel,
  onDismiss, // This is an optional prop that will add AND handle a close "X" icon
  fullscreen,
  ...rest
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile =
    useMediaQuery(theme.breakpoints.down('xs'), { defaultMatches: true }) ===
    true
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      classes={{ paper: classes.paper }}
      fullScreen={fullscreen}
      {...rest}
    >
      {title && (
        <div className={classes.title}>
          {title}
          {onDismiss && <CloseIcon onDismiss={onDismiss} />}
        </div>
      )}
      {description && (
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogText}>
            {description}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions className={classes.actions}>
        <Button
          onClick={onCancel}
          disabled={loading}
          className={clsx(classes.button, classes.cancelButton)}
          color="inherit"
          variant={isMobile ? 'text' : 'outlined'}
          data-testid="alert-dialog-cancel"
          id="alert-dialog-cancel"
        >
          {cancelText}
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          disabled={loading}
          className={classes.button}
          variant="contained"
          data-testid="alert-dialog-confirm"
          id="alert-dialog-confirm"
          style={{ margin: isMobile ? 0 : 8 }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlertDialog
