// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type GenerateQuoteMutationVariables = Types.Exact<{
  addressInfo?: Types.InputMaybe<Types.AddressInfoType>;
  input: Array<Types.ItemToQuote> | Types.ItemToQuote;
}>;


export type GenerateQuoteMutation = { __typename?: 'Mutation', generateQuote: { __typename?: 'QuoteGenerationResponse', orderNumber: string, sapOrderNumber: string, orderStatus: string } };


export const GenerateQuoteDocument = gql`
    mutation GenerateQuote($addressInfo: AddressInfoType, $input: [ItemToQuote!]!) {
  generateQuote(addressInfo: $addressInfo, input: $input) {
    orderNumber
    sapOrderNumber
    orderStatus
  }
}
    `;
export type GenerateQuoteMutationFn = ApolloReactCommon.MutationFunction<GenerateQuoteMutation, GenerateQuoteMutationVariables>;
export type GenerateQuoteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GenerateQuoteMutation, GenerateQuoteMutationVariables>, 'mutation'>;

    export const GenerateQuoteComponent = (props: GenerateQuoteComponentProps) => (
      <ApolloReactComponents.Mutation<GenerateQuoteMutation, GenerateQuoteMutationVariables> mutation={GenerateQuoteDocument} {...props} />
    );
    

/**
 * __useGenerateQuoteMutation__
 *
 * To run a mutation, you first call `useGenerateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateQuoteMutation, { data, loading, error }] = useGenerateQuoteMutation({
 *   variables: {
 *      addressInfo: // value for 'addressInfo'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateQuoteMutation, GenerateQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GenerateQuoteMutation, GenerateQuoteMutationVariables>(GenerateQuoteDocument, options);
      }
export type GenerateQuoteMutationHookResult = ReturnType<typeof useGenerateQuoteMutation>;
export type GenerateQuoteMutationResult = ApolloReactCommon.MutationResult<GenerateQuoteMutation>;
export type GenerateQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateQuoteMutation, GenerateQuoteMutationVariables>;