import React, { ReactNode, CSSProperties } from 'react'
import clsx from 'clsx'
import { FieldProps, getIn } from 'formik'

import LiquidQuantityInput from '../LiquidQuantityInput'
import { LiquidInputProps } from '../LiquidInput/LiquidInput'
import LiquidFormLabel from '../LiquidFormLabel'
import LiquidFormHelpText from '../LiquidFormHelpText'

interface LiquidInputAdapterProps
  extends FieldProps,
    Omit<LiquidInputProps, 'onChange'> {
  rootClass?: string
  rootStyle?: CSSProperties
  label?: ReactNode
  required?: boolean
  help?: ReactNode
  delay?: boolean
  min?: number
  type?: string
  handleBlur?: (event: any) => void
  associatedComponent?: string
  onChange?: (value: number) => void
}

const LiquidInputAdapter: React.FC<LiquidInputAdapterProps> = ({
  rootClass,
  rootStyle,
  size = 'medium',
  field,
  form,
  label,
  required,
  help,
  delay,
  min,
  type,
  onChange,
  ...otherProps
}) => {
  const error = getIn(form.errors, field.name)
  const touched = getIn(form.touched, field.name)
  const hasError = error && touched
  const handleChange = (v: any) => {
    form.setFieldValue(field.name, v)
    form.setFieldTouched(field.name)
    onChange && onChange(v)
  }

  return (
    <div
      className={clsx({
        [String(rootClass)]: rootClass,
      })}
      style={rootStyle}
    >
      {label && (
        <LiquidFormLabel htmlFor={field.name} size={size} required={required}>
          {label}
        </LiquidFormLabel>
      )}
      <LiquidQuantityInput
        {...otherProps}
        id={`${type}-${form.values.materialNumber}-${field.name}`}
        value={field.value}
        onChange={handleChange}
        delay={delay}
        size={size}
        error={Boolean(hasError)}
        aria-describedby={(hasError || help) && `${field.name}-help-text`}
        onBlur={() => {
          if (min && field.value < min) {
            form.setFieldValue(field.name, 1)
          }
        }}
        min={min}
      />
      {(hasError || help) && (
        <LiquidFormHelpText
          id={`${field.name}-help-text`}
          error={Boolean(hasError)}
        >
          {hasError ? error : help ? help : null}
        </LiquidFormHelpText>
      )}
    </div>
  )
}

export default LiquidInputAdapter
