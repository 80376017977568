import React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { Typography, Grid, Button, Divider, Modal } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import messages from '@utils/messages'
import ItemDetails from './ItemDetails'
import { Formik, Field, Form, FieldProps } from 'formik'
import ResponsiveModal, {
  ModalSizes,
  ResponsiveModalActions,
  ResponsiveModalBody,
} from '@src/components/ResponsiveModal/ResponsiveModal'
import { useQuickUpdateCart, useSubmitCart } from '@src/utils/useCart'
import AvailabilityMessage from '@src/components/AvailabilityMessage'
import { cartRoute, CartType, CartView } from '@src/routes'
import { useRouter } from 'next/router'
import { getCartMiniAction } from '@src/utils/cartUtils'
import AddressBlock from '@src/components/AddressBlock'
import { CartQuery } from '@src/queries/CartQuery.generated'
import LiquidAutoSaveInput from '../LiquidAutoSaveInput'
import { CartRequestAction } from '@src/types/graphql-types'
import {
  SnackbarMessages,
  useGlobalSnackbar,
} from '../GlobalSnackbar/globalSnackBarContext'
import { useValidators } from '@src/utils/validators'
import CircleLoader from '@src/components/CircleLoader/CircleLoader'
import { useCountriesData } from '@src/utils/useCountriesData'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import HandleMarkup from '@src/components/HandleMarkup'
import { TrackedAvailabilityMessageProvider } from '@src/components/TrackedAvailabilityMessage/TrackedAvailabilityMessageContext'
import { sendBuyItNowClickEvent } from '@src/utils/analytics'
import { BuyItNowEventActionEnum } from '@utils/analytics/enums'
import { getCartItemPricing } from '@src/components/TrackedAvailabilityMessage/utils'

const useStyles = makeStyles((theme: Theme) => ({
  editLink: {
    marginTop: theme.spacing(1),
  },
  divider: {
    backgroundColor: theme.palette.grey[300],
    height: 2,
    margin: theme.spacing(4, 0),
    width: '100%',
  },
  input: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    borderBottom: `2px solid ${theme.palette.grey[300]}`,
    borderTop: `2px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
    flexDirection: 'column',
    gridRowGap: theme.spacing(4),
  },
  cvvInput: {
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      maxWidth: 70,
    },
  },
  poInput: {
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      minWidth: 290,
    },
  },
  inputLabel: {
    marginRight: theme.spacing(3),
  },
  actionContainer: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  availabilityMessage: {
    fontSize: theme.typography.pxToRem(14),
    paddingLeft: theme.spacing(5),
  },
  totalLabel: {
    marginLeft: theme.spacing(1),
  },
  merchantLocation: {
    margin: theme.spacing(2, 0),
  },
  columnOffset: {
    paddingLeft: theme.spacing(5),
  },
  infoContainer: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    lineHeight: 1.71,
  },
  cvvInputWrapper: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    borderBottom: `2px solid ${theme.palette.grey[300]}`,
    borderTop: `2px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
  },
  gridAlign: {
    alignItems: 'center',
  },
}))

interface Props {
  cart: CartQuery['getCart']
  onClose: (reason?: string) => void
}

const BuyNowModal: React.FC<Props> = ({ onClose, cart }) => {
  const classes = useStyles()
  const router = useRouter()
  const [submitCart, { loading: cartSubmitting }] = useSubmitCart()
  const { setSnackbar } = useGlobalSnackbar()
  const [quickUpdateCart, { loading: updating }] = useQuickUpdateCart()
  const { country, language } = router.query
  const { required } = useValidators()
  const { getCountryDetails } = useCountriesData()
  const { currentUser } = useCurrentUser()

  const userCountryCode =
    currentUser?.__typename === 'LoggedInUser'
      ? currentUser?.metadata?.country
      : ''
  const userCountryInfo = getCountryDetails(userCountryCode)
  const isCCVRequired = !!userCountryInfo?.isCCVRequired

  const {
    items: [firstItem],
    totals,
    paymentMethods,
    selectedPaymentMethod,
    shippingAddress,
    currency,
    merchantLocation,
    count,
  } = cart

  const creditCardPayment = selectedPaymentMethod === 'CC'
  const standardPayment = selectedPaymentMethod === 'STD'
  const hasAttentionToValue = Boolean(shippingAddress?.attentionTo?.value)
  const materialNumbers = cart?.items?.map((item) => item.material.number)

  const handleCartAutoSave = async (input) => {
    try {
      quickUpdateCart({
        variables: {
          input,
          action: CartRequestAction.Buyitnowcartmini,
        },
      })
    } catch (error) {
      setSnackbar(SnackbarMessages.GenericError)
    }
  }

  const handleSubmit = async ({ cardSecret }) => {
    if (updating) return
    try {
      const { data } = await submitCart({
        variables: {
          action: getCartMiniAction(CartType.BuyNowCart),
          isDuplicateCheck: false,
          isQuote: false,
          totalProduct: count ?? 1,
          cvv: isCCVRequired ? cardSecret : undefined,
        },
      })

      sendBuyItNowClickEvent(
        materialNumbers,
        BuyItNowEventActionEnum.SubmitOrder
      )

      if (data?.submitCart?.orderNumber) {
        router.push(
          `/${country}/${language}/${cartRoute[CartView.OrderAcknowledgement](
            CartType.BuyNowCart,
            data?.submitCart?.orderNumber || ''
          )}`
        )
      }
    } catch (error) {
      setSnackbar(SnackbarMessages.GenericError)
    }
  }

  const handleEdit = () => {
    sendBuyItNowClickEvent(
      materialNumbers,
      BuyItNowEventActionEnum.EditBuyItNowCheckoutDetails
    )

    router.push(
      `/${country}/${language}/${cartRoute.checkout(
        CartType.BuyNowCart
      )}&material=${firstItem?.material?.number}`
    )
  }

  return (
    <>
      {cartSubmitting && (
        <Modal open hideBackdrop>
          <CircleLoader />
        </Modal>
      )}

      <ResponsiveModal
        open
        onClose={(_, reason) => onClose(reason)}
        size={ModalSizes.Large}
        renderTitle={() => (
          <Typography variant="h2">
            <FormattedMessage {...messages.BUY_IT_NOW} />
          </Typography>
        )}
      >
        <ResponsiveModalBody>
          {firstItem.quoteNumber ? (
            <div className={classes.infoContainer}>
              <Typography variant="inherit">
                <FormattedMessage
                  id="QUOTE_DISCLAIMER"
                  defaultMessage="Clicking “Submit Order” will confirm the purchase of your quoted items. {br}
      It will not affect any items in your Shopping Cart."
                  values={{ br: <br /> }}
                />
              </Typography>
            </div>
          ) : (
            <ItemDetails item={firstItem} />
          )}
          <Formik
            validateOnMount
            initialValues={{
              poNumber: paymentMethods?.purchaseOrder?.value,
              cardSecret: null,
            }}
            onSubmit={handleSubmit}
          >
            {(formikBag) => {
              return (
                <Form>
                  {(!hasAttentionToValue || standardPayment) && (
                    <div className={classes.input}>
                      {!hasAttentionToValue && (
                        <Grid container className={classes.gridAlign}>
                          <Grid xs={5} sm={3}>
                            <Typography variant="subtitle2">
                              <FormattedMessage
                                id="ATTENTION_TO_REQUIRED"
                                defaultMessage="Attention to (required)"
                              />
                            </Typography>
                          </Grid>
                          <Grid xs={7} sm={4}>
                            <Field name="attentionTo" validate={required}>
                              {({ field }: FieldProps) => (
                                <LiquidAutoSaveInput
                                  rootClass={classes.poInput}
                                  {...field}
                                  filled
                                  saveOnError
                                  handleSave={(value) => {
                                    handleCartAutoSave({
                                      shippingAddress: {
                                        attentionTo: value.trim(),
                                      },
                                    })
                                  }}
                                />
                              )}
                            </Field>
                          </Grid>
                        </Grid>
                      )}
                      {standardPayment && (
                        <Grid container className={classes.gridAlign}>
                          <Grid xs={5} sm={3}>
                            <Typography
                              variant="subtitle2"
                              className={classes.inputLabel}
                            >
                              <FormattedMessage
                                id="BIN_PO_NUMBER_REQUIRED"
                                defaultMessage="PO Number (required)"
                              />
                            </Typography>
                          </Grid>

                          <Grid xs={7} sm={4}>
                            <Field name="poNumber" validate={required}>
                              {({ field }: FieldProps) => (
                                <LiquidAutoSaveInput
                                  rootClass={classes.poInput}
                                  {...field}
                                  filled
                                  saveOnError
                                  handleSave={(value) => {
                                    handleCartAutoSave({
                                      paymentMethods: {
                                        purchaseOrder: value.trim(),
                                      },
                                    })
                                  }}
                                />
                              )}
                            </Field>
                          </Grid>
                        </Grid>
                      )}
                    </div>
                  )}
                  {creditCardPayment ? (
                    <>
                      <Grid container>
                        <Grid item xs={3} sm={2}>
                          <Typography variant="subtitle2">
                            <FormattedMessage {...messages.CREDIT_CARD_LABEL} />
                            :
                          </Typography>
                        </Grid>
                        <Grid item xs={9} sm={10}>
                          <Typography
                            variant="inherit"
                            className={classes.columnOffset}
                          >
                            {paymentMethods?.creditCard?.maskedCardNumber}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container className={classes.merchantLocation}>
                        <Grid item sm={2} xs={3}>
                          <Typography variant="subtitle2">
                            <FormattedMessage {...messages.MERCHANT_LOCATION} />
                            :
                          </Typography>
                        </Grid>

                        <Grid
                          className={classes.columnOffset}
                          item
                          sm={10}
                          xs={9}
                        >
                          <Typography variant="inherit">
                            <HandleMarkup
                              value={merchantLocation
                                ?.split(',')
                                .join('<br />')}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                      {!isCCVRequired && (
                        <Divider className={classes.divider} />
                      )}
                      {isCCVRequired ? (
                        <Grid className={classes.cvvInputWrapper}>
                          <Grid item xs={8} sm={4}>
                            <Typography
                              variant="subtitle2"
                              className={classes.inputLabel}
                            >
                              <FormattedMessage
                                id="CREDIT_CARD_CVV_REQUIRED"
                                defaultMessage="Credit Card CVV Number (required)"
                              />
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sm={1}>
                            <Field
                              name="cardSecret"
                              validate={(value) =>
                                required(value) &&
                                paymentMethods?.creditCard?.cardType === 'AX'
                                  ? value?.length !== 4
                                  : value?.length !== 3
                              }
                            >
                              {({ field, form }: FieldProps) => (
                                <LiquidAutoSaveInput
                                  rootClass={classes.cvvInput}
                                  {...field}
                                  filled
                                  saveOnError
                                  handleSave={(value) => {
                                    form.setFieldValue('cardSecret', value)
                                  }}
                                />
                              )}
                            </Field>
                          </Grid>
                        </Grid>
                      ) : null}
                    </>
                  ) : null}
                  {firstItem.quoteNumber && (
                    <>
                      <Grid container>
                        <Grid item xs={3} sm={2}>
                          <Typography variant="subtitle2">
                            <FormattedMessage {...messages.QUOTE_NUMBER} />:
                          </Typography>
                        </Grid>
                        <Grid item xs={9} sm={10}>
                          <Typography
                            variant="inherit"
                            className={classes.columnOffset}
                          >
                            {firstItem.quoteNumber}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider className={classes.divider} />
                    </>
                  )}

                  <Grid>
                    <Grid container>
                      <Grid item xs={3} sm={2}>
                        <Typography variant="subtitle2">
                          <FormattedMessage {...messages.SHIP_TO} />:
                        </Typography>
                      </Grid>
                      <Grid item xs={9} sm={10}>
                        <AddressBlock
                          className={classes.columnOffset}
                          attentionTo={shippingAddress?.attentionTo?.value}
                          organizationName={
                            shippingAddress?.organizationName?.value
                          }
                          street={shippingAddress?.street1?.value}
                          city={shippingAddress?.city?.value}
                          state={shippingAddress?.state?.value}
                          postalCode={shippingAddress?.postalCode?.value}
                          country={shippingAddress?.country?.value}
                          showStateName
                        />
                      </Grid>
                    </Grid>

                    <Divider className={classes.divider} />

                    {!firstItem.quoteNumber ? (
                      <>
                        <Grid container>
                          <Grid item xs={3} sm={2}>
                            <Typography variant="subtitle2">
                              <FormattedMessage {...messages.AVAILABILITY} />:
                            </Typography>
                          </Grid>
                          <Grid item xs={9} sm={10}>
                            <Typography
                              variant="inherit"
                              className={classes.columnOffset}
                            >
                              {firstItem?.carrierName}
                              {firstItem?.availabilityMessages && (
                                <TrackedAvailabilityMessageProvider
                                  source="buy it now modal"
                                  item={firstItem.material.number || undefined}
                                  brand={
                                    firstItem.material.brand.key ||
                                    firstItem.brand ||
                                    undefined
                                  }
                                  pricing={getCartItemPricing(firstItem)}
                                >
                                  <AvailabilityMessage
                                    className={classes.availabilityMessage}
                                    availabilities={firstItem?.availabilityMessages.map(
                                      (item) => ({
                                        key: item && item.key,
                                        date: item && item.date,
                                        plantLoc: item && item.plantLoc,
                                        quantity: item && item.quantity,
                                      })
                                    )}
                                  />
                                </TrackedAvailabilityMessageProvider>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                      </>
                    ) : null}

                    <Grid container>
                      <Grid item xs={3} sm={2}>
                        <Typography variant="subtitle2">
                          <FormattedMessage id="TOTAL" defaultMessage="Total" />
                          :
                        </Typography>
                      </Grid>
                      {currency && totals?.total ? (
                        <Grid
                          className={classes.columnOffset}
                          item
                          sm={10}
                          xs={9}
                        >
                          <Typography variant="inherit">
                            <FormattedNumber
                              style="currency"
                              currencyDisplay="symbol"
                              currency={currency}
                              value={totals?.total}
                            />
                            <Typography
                              className={classes.totalLabel}
                              component="span"
                            >
                              <FormattedMessage
                                {...messages.INCLUDES_TAXES_FEES_AND_SHIPPING}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>

                  <ResponsiveModalActions className={classes.actionContainer}>
                    <Button
                      color="secondary"
                      disabled={formikBag.isSubmitting || !formikBag.isValid}
                      size="large"
                      onClick={() => handleSubmit(formikBag?.values)}
                      variant="contained"
                    >
                      <FormattedMessage {...messages.SUBMIT_ORDER} />
                    </Button>
                    <Button onClick={handleEdit}>
                      <FormattedMessage {...messages.EDIT_DETAILS} />
                    </Button>
                  </ResponsiveModalActions>
                </Form>
              )
            }}
          </Formik>
        </ResponsiveModalBody>
      </ResponsiveModal>
    </>
  )
}

export default BuyNowModal
