import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Typography, Button, Modal } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import messages from '@utils/messages'
import ItemDetails from './ItemDetails'
import { Formik, Form } from 'formik'
import ResponsiveModal, {
  ModalSizes,
  ResponsiveModalActions,
  ResponsiveModalBody,
} from '@src/components/ResponsiveModal/ResponsiveModal'
import { quickOrderRoute } from '@src/routes'
import { useRouter } from 'next/router'
import { CartQuery } from '@src/queries/CartQuery.generated'
import {
  SnackbarMessages,
  SnackbarType,
  useGlobalSnackbar,
} from '../GlobalSnackbar/globalSnackBarContext'
import CircleLoader from '@src/components/CircleLoader/CircleLoader'
import GlobalAlert from '../GlobalAlert'
import WarningCircleIcon from '@src/icons/WarningCircleIcon'
import { useB2bShoppingCartLazyQuery } from '@src/queries/B2bShoppingCart.generated'
import { CartRequestAction } from '@src/types/graphql-types'
import { extractData, OriginalError } from '@src/utils/errorHandler'
import { useUserSession } from '@src/utils/useUserSession'
import postB2bData from '@utils/b2bExternalRedirect'
import { useAddToCart } from '@src/utils/useCart'
import { getCartMiniAction } from '@src/utils/cartUtils'
import { useCartView } from '@src/routes/Cart/useCartView'
import { QUICK_CART } from '@src/queries/QuickCartQuery'
import { useGlobalModal } from '@src/utils/useGlobalModal'
import {
  sendB2BBuyNowModalAddToCart,
  sendB2BBuyNowModalTransferCart,
} from '@utils/analytics/cartAndCheckoutEvents'

const useStyles = makeStyles((theme: Theme) => ({
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(8),
  },
}))

interface Props {
  cart: CartQuery['getCart']
  onClose: () => void
}

const BuyNowModalB2B: React.FC<Props> = ({ cart, onClose }) => {
  const classes = useStyles()
  const router = useRouter()
  const { cartType } = useCartView()
  const { setSnackbar, setGlobalSnackbarState } = useGlobalSnackbar()
  const { clearGlobalModalComponent } = useGlobalModal()
  const { userSessionActions } = useUserSession()
  const [addToCart] = useAddToCart()
  const intl = useIntl()
  const [item] = cart?.items

  const [
    fetchB2BCart,
    { data: b2bCartData, loading: b2bCartSubmitting, error: b2bCartError },
  ] = useB2bShoppingCartLazyQuery({
    variables: { cartType: CartRequestAction.Buyitnowcart },
  })

  const handleTransferItem = async () => {
    try {
      await fetchB2BCart()
      sendB2BBuyNowModalTransferCart()
    } catch (error) {
      const { displayableError } = extractData(error as OriginalError)
      setGlobalSnackbarState({
        open: true,
        message: displayableError || (
          <FormattedMessage {...messages.GENERIC_ERROR} />
        ),
        variant: SnackbarType.Error,
      })
    }
  }

  const handleAddToCart = async () => {
    const materialNumber = item?.material?.number
    const quantity = item?.quantity

    try {
      await addToCart({
        variables: {
          input: { items: [{ materialNumber, quantity }] },
        },
        refetchQueries: [
          { query: QUICK_CART, variables: { id: getCartMiniAction(cartType) } },
        ],
        awaitRefetchQueries: true,
      })
      sendB2BBuyNowModalAddToCart()
      clearGlobalModalComponent()
    } catch (error) {
      const { displayableError } = extractData(error as OriginalError)
      setGlobalSnackbarState({
        open: true,
        message: displayableError || (
          <FormattedMessage {...messages.GENERIC_ERROR} />
        ),
        variant: SnackbarType.Error,
      })
    }
  }

  // render error message and navigate to the quick order page since cart will be empty
  const handlePostBackURLError = async () => {
    const code = 'DATA_ERROR'
    const errorMessage = intl.formatMessage({ id: code })
    setSnackbar(SnackbarMessages.OciCheckoutError, errorMessage)
    await userSessionActions.b2bLogout()
    router.replace(quickOrderRoute.index())
  }

  // post cart data and redirect to b2b punchout website
  const handleB2bPunchoutRedirect = async (data) => {
    const { postBackURL, punchoutResponseMsg, isDecodeNeeded, isSapOci } = data

    await userSessionActions.b2bLogout()
    postB2bData(postBackURL, punchoutResponseMsg, isDecodeNeeded, isSapOci)
  }

  // if postBackURL is empty or undefined, render an error and logout the user
  // otherwise redirect to b2b punchout website
  useEffect(() => {
    if (!b2bCartData?.getB2bShoppingCart) return

    const { postBackURL, punchoutResponseMsg } = b2bCartData?.getB2bShoppingCart

    if (postBackURL === undefined || postBackURL === '') {
      handlePostBackURLError()
    } else if (punchoutResponseMsg && postBackURL && postBackURL !== '') {
      handleB2bPunchoutRedirect(b2bCartData?.getB2bShoppingCart)
    }
  }, [b2bCartData])

  useEffect(() => {
    if (!b2bCartError) return

    const { displayableError } = extractData(b2bCartError as OriginalError)
    setGlobalSnackbarState({
      open: true,
      message: displayableError || (
        <FormattedMessage {...messages.GENERIC_ERROR} />
      ),
      variant: SnackbarType.Error,
    })
  }, [b2bCartError])

  return (
    <>
      {b2bCartSubmitting && (
        <Modal open hideBackdrop>
          <CircleLoader />
        </Modal>
      )}

      <ResponsiveModal
        open
        onClose={onClose}
        size={ModalSizes.Large}
        renderTitle={() => (
          <Typography variant="h2">
            <FormattedMessage {...messages.BUY_IT_NOW} />
          </Typography>
        )}
      >
        <ResponsiveModalBody>
          <ItemDetails item={item} />

          <Formik initialValues={{}} onSubmit={handleTransferItem}>
            {(formikBag) => {
              return (
                <Form>
                  <GlobalAlert
                    severity="warning"
                    icon={<WarningCircleIcon style={{ fontSize: 20 }} />}
                  >
                    <FormattedMessage
                      {...messages.TRANSFER_ITEM_MESSAGE}
                      values={{
                        br: <br />,
                        thisItemOnly: (
                          <u>
                            <FormattedMessage {...messages.THIS_ITEM_ONLY} />
                          </u>
                        ),
                      }}
                    />
                  </GlobalAlert>

                  <ResponsiveModalActions className={classes.actionContainer}>
                    <Button
                      color="secondary"
                      disabled={formikBag.isSubmitting}
                      onClick={handleTransferItem}
                      size="large"
                      variant="contained"
                    >
                      <FormattedMessage {...messages.TRANSFER_ITEM} />
                    </Button>
                    <Button
                      color="secondary"
                      disabled={formikBag.isSubmitting}
                      onClick={handleAddToCart}
                      size="large"
                      variant="contained"
                    >
                      <FormattedMessage
                        id="ADD_TO_CART"
                        defaultMessage="Add to Cart"
                      />
                    </Button>
                  </ResponsiveModalActions>
                </Form>
              )
            }}
          </Formik>
        </ResponsiveModalBody>
      </ResponsiveModal>
    </>
  )
}

export default BuyNowModalB2B
