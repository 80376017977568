import React, { FC } from 'react'

interface ResponsiveCatalogImageProps
  extends React.HTMLAttributes<HTMLImageElement> {
  sizes: string
  urls: string[]
  src: string
  alt?: string | null
  width?: string
  height?: string
}

// Matches the characters ` _w` and any number of consecutive digits
// e.g. _w12, _w525, _w209384750342985724305978
const SPECIFIC_WIDTHS_PATTERN = / _w\d{1,}/g

/**
 * NOTE: This method at one point supported building srcSets based
 * on rendition types (e.g. -small, -medium, -large). That was rolled
 * back because those images were not consistently sized. If images
 * are re-processed so that small, medium, and large are consistent,
 * reference SHA 0081c0819 for the diff.
 */
const buildSrcSetFromUrls = (urls: string[]) => {
  return urls.reduce((srcSet, url, index) => {
    const hasWidthInURL = SPECIFIC_WIDTHS_PATTERN.test(url)

    if (!hasWidthInURL) {
      return ''
    }

    const separator = index < urls.length - 1 ? ', ' : ''
    const widths = url.match(SPECIFIC_WIDTHS_PATTERN) ?? []
    const width = widths[0]?.split('w')[1]

    if (width?.length === 0) {
      return srcSet
    }

    return `${srcSet}${url} ${width}w${separator}`
  }, '')
}

const getResponsiveImageAttributes = (
  urls: string[],
  src: string,
  sizes: string
) => {
  const uniqueURLs = Array.from(new Set(urls))

  if (uniqueURLs.length < 2) {
    return {}
  }

  const srcSet = buildSrcSetFromUrls(uniqueURLs)

  if (srcSet.length === 0 || srcSet === src) {
    return {}
  }

  return {
    srcSet,
    sizes,
  }
}

const ResponsiveCatalogImage: FC<ResponsiveCatalogImageProps> = ({
  sizes,
  src,
  urls,
  alt = '',
  ...props
}) => {
  const attributes = getResponsiveImageAttributes(urls, src, sizes)
  return <img alt={alt || ''} src={src} {...attributes} {...props} />
}

export default ResponsiveCatalogImage
