import { CartItemFragment } from '@src/queries/CartQuery.generated'
import { Pricing } from './TrackedAvailabilityMessageContext'

export const getCartItemPricing = (cartItem: CartItemFragment): Pricing => ({
  ...cartItem.pricing,
  currency: cartItem.currency,
  listPriceCurrency: cartItem.listPriceCurrency,
  promotionalMessage: cartItem.promotionalMessage,
  supplementaryMessage: cartItem.supplementaryMessage,
  availabilityOverwriteMessage: cartItem.availabilityOverwriteMessage,
})
