// Handle form submission if fetch is not applicable
const handleFormSubmission = (
  postBackURL: string,
  punchoutResponseMsg: string,
  isDecodeNeeded: boolean,
  isSapOci: boolean
): void => {
  const form = document.createElement('FORM') as HTMLFormElement
  form.method = 'POST'
  form.enctype = 'application/x-www-form-urlencoded'
  form.style.display = 'none'
  form.action = postBackURL

  if (isSapOci) {
    const inputVals = JSON.parse(punchoutResponseMsg)
    Object.keys(inputVals).forEach((key) => {
      const input = document.createElement('INPUT') as HTMLInputElement
      input.name = key
      input.value = inputVals[key]
      form.appendChild(input)
    })
  } else {
    const input = document.createElement('INPUT') as HTMLInputElement
    input.type = 'hidden'
    input.name = isDecodeNeeded ? 'cXML-urlencoded' : 'cXML-base64'
    input.value = isDecodeNeeded
      ? decodeURIComponent(
          Array.from(atob(punchoutResponseMsg))
            .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
        )
      : punchoutResponseMsg
    form.appendChild(input)
  }

  document.body.appendChild(form)
  form.submit()
}

// Strip credentials from the postBackURL only if they are present
const stripCredentialsFromURL = (postBackURL: string): string => {
  const url = new URL(postBackURL)
  if (url.username || url.password) {
    url.username = ''
    url.password = ''
  }
  return url.toString()
}

// Helper function to validate punchoutData
const isValidPunchoutData = (data: any): boolean => {
  return (
    data.header &&
    Array.isArray(data.header) &&
    data.header.length > 0 &&
    data.header[0].username &&
    data.header[0].password
  )
}

// Handle fetch request submission for oci customer
const handleFetchSubmission = (
  punchoutData: any,
  postBackURL: string,
  redirectURL?: string
): void => {
  const { username, password } = punchoutData.header[0]
  const credentials = `${username}:${password}`
  const encodedCredentials = btoa(credentials)
  const authHeader = `Basic ${encodedCredentials}`

  // Strip credentials only if present in the postBackURL
  const sanitizedURL = stripCredentialsFromURL(postBackURL)

  const options: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authHeader,
    },
    body: JSON.stringify(punchoutData),
  }

  fetch(sanitizedURL, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`)
      }
      return response.json()
    })
    .then(() => {
      // Redirect the user to the target URL after successful JSON submission
      const targetURL = redirectURL || sanitizedURL
      window.location.href = targetURL
    })
    .catch((error) => {
      console.error('There was an error with the JSON request:', error)
    })
}

// Main function to handle data submission
const postB2bData = (
  postBackURL: string,
  punchoutResponseMsg: string,
  isDecodeNeeded: boolean,
  isSapOci: boolean,
  redirectURL?: string
): void => {
  // Try to parse the punchoutResponseMsg as JSON, if it fails, fall back to regular form submission flow
  let punchoutData
  try {
    punchoutData = JSON.parse(punchoutResponseMsg)
  } catch (error) {
    handleFormSubmission(
      postBackURL,
      punchoutResponseMsg,
      isDecodeNeeded,
      isSapOci
    )
    return
  }

  // If parsed as JSON, check if it contains the required header with username and password
  if (isValidPunchoutData(punchoutData)) {
    handleFetchSubmission(punchoutData, postBackURL, redirectURL)
  } else {
    // If JSON doesn't contain required data, fall back to form submission
    handleFormSubmission(
      postBackURL,
      punchoutResponseMsg,
      isDecodeNeeded,
      isSapOci
    )
  }
}

export default postB2bData
