import React from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Link as MuiLink } from '@material-ui/core'
import { Link } from '@src/components/Link'
import { productDetailRoute } from '@src/routes'
import messages from '@utils/messages'
import HandleMarkup from '../HandleMarkup'
import ResponsiveCatalogImage from '@src/components/ResponsiveCatalogImage'
import { sendBuyItNowClickEvent } from '@src/utils/analytics'
import { BuyItNowEventActionEnum } from '@utils/analytics/enums'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    marginBottom: theme.spacing(5),
  },
  details: { overflow: 'hidden' },
  imageContainer: {
    width: 60,
    height: 60,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    border: `solid 1px ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    wordWrap: 'break-word',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  materialNumber: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'block',
    fontSize: theme.typography.pxToRem(14),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  materialName: {
    fontWeight: theme.typography.fontWeightBold,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  quantity: {
    fontSize: theme.typography.pxToRem(12),
  },
}))

interface ItemDetailProps {
  item: any
}

const ItemDetails: React.FC<ItemDetailProps> = ({ item }) => {
  const classes = useStyles()

  // Products can contain special characters which need to be removed for their PDP URL
  const productKey = item?.material?.product?.replace(/[\W_]+/g, '')

  const handleClick = () => {
    sendBuyItNowClickEvent(
      [item?.material?.number],
      BuyItNowEventActionEnum.OpenProductDetailPage
    )
  }
  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        {item?.material?.image ? (
          <ResponsiveCatalogImage
            alt={item?.material?.number}
            className={classes.image}
            sizes={'60px'}
            src={item?.material?.image.smallUrl}
            urls={[
              item?.material?.image.smallUrl,
              item?.material?.image.mediumUrl,
              item?.material?.image.largeUrl,
            ]}
          />
        ) : null}
      </div>

      <div className={classes.details}>
        {item?.material?.brand ? (
          <Link
            {...productDetailRoute.index(
              (item?.material?.brand.key || item?.material?.brand.name || '')
                .replace(' ', '')
                .toLowerCase(),
              productKey || item?.material?.id.split('-')[0]
            )}
            passHref
          >
            <MuiLink className={classes.materialNumber} onClick={handleClick}>
              {item?.material?.number}
            </MuiLink>
          </Link>
        ) : null}
        {item?.material?.name ? (
          <div className={classes.materialName}>
            <HandleMarkup value={item?.material?.name} />
          </div>
        ) : null}
        <div className={classes.quantity}>
          <FormattedMessage {...messages.QUANTITY} />:{' '}
          {item?.quantity === '' ? '1' : item?.quantity}
        </div>
      </div>
    </div>
  )
}

export default ItemDetails
