import React from 'react'
import { useCountryName } from '@utils/regional'
import { useUserSession } from '@utils/useUserSession'
import { useCountryStatesQuery } from '@src/queries/CountryStatesQuery.generated'
import { useChinaUser } from '@utils/useChinaUser'
import { getStateNameFromSial } from '@src/utils/getStateName'
import { useCurrentUser } from '@utils/useCurrentUser'

export interface AddressBlockProps {
  className?: string
  attentionTo?: string | null
  organizationName?: string | null
  department?: string | null
  buildingRoom?: string | null
  street?: string | null
  street3?: string | null
  street4?: string | null
  postOfficeBox?: string | null
  city?: string | null
  state?: string | null
  showStateName?: boolean
  postalCode?: string | null
  country?: string | null
  recipientName?: string | null
  mobile?: string | null
  isShippingAddress?: boolean
}

const AddressBlock: React.FC<AddressBlockProps> = ({
  className,
  attentionTo,
  organizationName,
  department,
  buildingRoom,
  street,
  street3,
  street4,
  postOfficeBox,
  city,
  state,
  showStateName = false,
  postalCode,
  country,
  recipientName,
  mobile,
  isShippingAddress = false,
}) => {
  const { userSession } = useUserSession()
  const { country: userCountry, language: userLanguage } = userSession
  const isChinaUser = useChinaUser()
  const countryName = useCountryName(country) || country
  const isDarmstadtUser = useCurrentUser()

  const {
    data: statesData,
    loading: statesLoading,
    error: statesError,
  } = useCountryStatesQuery({
    variables: {
      country: country || userCountry,
      locale:
        userLanguage === 'en' ? 'en_US' : `${userLanguage}_${userCountry}`,
    },
    skip: !showStateName,
  })

  const getStateName = (state) => {
    if (statesLoading || statesError) return state

    if (isChinaUser) {
      return getStateNameFromSial(isChinaUser, state)
    }

    const stateObj = statesData?.getCountryStates.find(
      (element) => element?.stateCode === state
    )
    return stateObj?.stateName || state
  }

  const renderChinaAddress = () => {
    return (
      <div className={className}>
        <div data-scrub>{recipientName}</div>
        <div data-scrub>{attentionTo}</div>
        <div data-scrub>{mobile}</div>
        <div data-scrub>{organizationName}</div>
        <div data-scrub>{department}</div>
        <div>
          {state && (
            <span data-scrub>
              {showStateName ? getStateName(state) : state},{' '}
            </span>
          )}
          <span data-scrub>{city}</span>
        </div>
        <div data-scrub>{street}</div>
        <div>
          {buildingRoom && <span data-scrub>{buildingRoom}, </span>}
          <span data-scrub>{postalCode}</span>
        </div>
      </div>
    )
  }

  if (isChinaUser && isShippingAddress) {
    return renderChinaAddress()
  }
  return (
    <div className={className}>
      <div data-scrub>{recipientName}</div>
      <div data-scrub>{attentionTo}</div>
      <div data-scrub>{organizationName}</div>
      <div data-scrub>{department}</div>
      <div data-scrub>{buildingRoom}</div>
      <div data-scrub>{street}</div>
      {isDarmstadtUser && (
        <>
          <div data-scrub>{street3}</div>
          <div data-scrub>{street4}</div>
        </>
      )}
      <div data-scrub>{postOfficeBox}</div>
      <div>
        {city && <span data-scrub>{city}, </span>}
        {state && (
          <span data-scrub>{showStateName ? getStateName(state) : state} </span>
        )}
        {postalCode && <span data-scrub>{postalCode}</span>}
      </div>
      <div data-scrub>{countryName}</div>
    </div>
  )
}

export default AddressBlock
