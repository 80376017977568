import { CartView, useRouter, CartType } from '@src/routes'
import { useQuickCart } from '@utils/useCart'
import { SitePreference, useCurrentUser } from '@utils/useCurrentUser'
import { getCartMiniAction } from '@utils/cartUtils'
import { CartItemType } from '@src/types/graphql-types'

export enum AddressTypes {
  Shipping = 'shipTos',
  Billing = 'billTos',
  Payer = 'payers',
}

export enum SelectAddressSections {
  Billing = 'billing',
  Shipping = 'shipping',
}

export enum ContractCartType {
  Buy = 'buy',
  Refill = 'refill',
  BlanketPo = 'blanketPo',
}

const redirectCustomerServiceRep = (quickCart, view) => {
  if (
    view !== CartView.CustomerSearch &&
    view !== CartView.OrderAcknowledgement &&
    !quickCart.data.getCart.partners.soldTo
  ) {
    /**
     * If there is no soldTo data attached to the cart of a customer service
     * rep they need to go through the process of adding the customer information
     * to that cart via customer search before they can go to the selectAddress page.
     */
    return CartView.CustomerSearch
  } else if (
    view !== CartView.SelectAddresses &&
    view !== CartView.CustomerSearch && // This allows going back to customer search in the breadcrumb
    view !== CartView.OrderAcknowledgement &&
    quickCart.data.getCart.partners.soldTo &&
    (!quickCart.data.getCart.partners.billTo ||
      !quickCart.data.getCart.partners.shipTo ||
      !quickCart.data.getCart.partners.payer)
  ) {
    /**
     * If there is a soldTo and no address information the CSR will need to select
     * an address.
     */
    return CartView.SelectAddresses
  }
  return null
}

export const useCartView = () => {
  const router = useRouter()
  const {
    currentUserLoading,
    isCustomerServiceRep,
    hasUniqueSoldTos,
    getSitePreference,
  } = useCurrentUser()
  const hideAddToCartForPrepackItems = getSitePreference(
    SitePreference.HideAddToCartForPrepackItems
  )
  const hideOligoPeptideDesign = getSitePreference(
    SitePreference.HideOligoPeptideDesign
  )
  const queryAction = router?.query?.action
  const cartType = router?.query?.type as CartType | undefined
  // The emailCart param comes from the link sent in emailed carts
  // The value of this param is a an id to an emailCart that needs to be merged with the existing user's cart
  // There is nothing preventing a user from clicking on the link in their email multiple times and having the same product added over and over
  const emailCartId = router?.query?.emailCart
  let view: CartView
  switch (queryAction) {
    case CartView.CustomerSearch:
    case CartView.SelectAccount:
    case CartView.SelectAddresses:
    case CartView.Checkout: {
      view = queryAction as CartView
      break
    }
    case CartView.OrderAcknowledgement: {
      view = CartView.OrderAcknowledgement
      break
    }
    case CartView.QuoteConfirmation: {
      view = CartView.QuoteConfirmation
      break
    }
    case CartView.SaveForLater: {
      view = CartView.SaveForLater
      break
    }
    case CartView.Inspect: {
      view = CartView.Inspect
      break
    }
    default: {
      view = CartView.Cart
    }
  }

  /**
   * We only need to use quick cart here to determine if the select account/
   * select addresses redirect is necessary. For rendering data, we only use
   * full cart to keep things simple.
   */
  const quickCart = useQuickCart({
    variables: {
      id: getCartMiniAction(cartType),
    },
    skip: view === CartView.OrderAcknowledgement,
  })

  let redirect: Maybe<CartView> = null

  let contractCartType: Maybe<string> = null

  if (quickCart.data && !currentUserLoading) {
    if (isCustomerServiceRep) {
      redirect = redirectCustomerServiceRep(quickCart, view)
    } else if (
      !isCustomerServiceRep &&
      !quickCart.data.getCart.partners.soldTo &&
      /**
       * If the user does not have unique soldTo partner numbers we
       * should use the single one provided and add that to the cart at select address page
       */
      hasUniqueSoldTos &&
      view !== CartView.SelectAccount &&
      view !== CartView.CustomerSearch &&
      view !== CartView.OrderAcknowledgement &&
      view !== CartView.QuoteConfirmation
    ) {
      redirect = CartView.SelectAccount
    } else if (
      !isCustomerServiceRep &&
      view === CartView.Checkout &&
      !(
        quickCart.data.getCart.partners.billTo ||
        quickCart.data.getCart.partners.contact ||
        quickCart.data.getCart.partners.payer ||
        quickCart.data.getCart.partners.shipTo
      )
    ) {
      redirect = CartView.SelectAddresses
    }
    if (cartType === CartType.ContractCart) {
      contractCartType = quickCart.data.getCart.contractNumber
        ? ContractCartType.Refill
        : ContractCartType.Buy
    }
    if (view === CartView.Checkout && hideAddToCartForPrepackItems) {
      const prepackItems = quickCart.data.getCart.items.filter(
        (item) => item.type === CartItemType.Prepack
      )
      if (prepackItems.length > 0) {
        redirect = CartView.Cart
      }
    }
    if (view === CartView.Checkout && hideOligoPeptideDesign) {
      const configuratorItems = quickCart.data.getCart.items.filter(
        (item) => item.type === CartItemType.Configurator
      )
      if (configuratorItems.length > 0) {
        redirect = CartView.Cart
      }
    }
  }
  /*  If request is coming from email cart link then we need to wait till BE add
      items to cart then only we can make fresh getFullCart call otherwise BE is throwing 409 error
      some times we are seeing duplicate merge cart call as we are using this custom hook in many components
      So moving email cart logic to separate view
  */

  if (emailCartId) {
    view = CartView.EmailCart
  }

  return { view, redirect, cartType, contractCartType }
}
