import React, { ReactNode, CSSProperties } from 'react'
import clsx from 'clsx'
import { useDebounce } from 'react-use'
import LiquidInput from '../LiquidInput'
import { LiquidInputProps } from '../LiquidInput/LiquidInput'
import LiquidFormLabel from '../LiquidFormLabel'
import LiquidFormHelpText from '../LiquidFormHelpText'

interface LiquidAutoSaveInputProps extends LiquidInputProps {
  name: string
  value: string
  handleSave: (v: any) => void
  saveOnError?: boolean
  onChange: (v: any) => void
  rootClass?: string
  rootStyle?: CSSProperties
  label?: ReactNode
  required?: boolean
  help?: ReactNode
  delay?: number
  component?: React.ComponentType<any>
  // We can't rely on only supplying the error message when the field is touched
  // because then it will auto-save with the error value; we have to always
  // supply the error message to the auto-save inputs, and pass displayError
  // to determine whether we want to show it or hide it.
  displayError?: boolean
  // Handle any additional props in case component !== LiquidInput
  [key: string]: unknown
}

const LiquidAutoSaveInput: React.FC<LiquidAutoSaveInputProps> = ({
  rootClass,
  rootStyle,
  label,
  required,
  help,
  size,
  error,
  name,
  value,
  handleSave,
  saveOnError,
  onChange,
  delay = 500,
  component = LiquidInput,
  displayError = true,
  ...otherProps
}) => {
  // Track the previous saved value so we don't duplicate save requests or save
  // on component mount.
  const prevValue = React.useRef(value)
  /*
   * Use a separate state value to track user changes vs. e.g. a new value from
   * a reinitialized form; we don't want to auto-save if the new value doesn't
   * represent a manual change by the user.
   */
  const [localValue, setLocalValue] = React.useState(value)
  useDebounce(
    () => {
      if ((!error || saveOnError) && localValue !== prevValue.current) {
        handleSave(localValue)
        prevValue.current = localValue
      }
    },
    delay,
    [localValue, !!error]
  )

  const InputComponent = component

  return (
    <div
      className={clsx({
        [String(rootClass)]: rootClass,
      })}
      style={rootStyle}
    >
      {label && (
        <LiquidFormLabel
          htmlFor={name}
          size={size}
          required={required}
          error={!!(displayError && error)}
        >
          {label}
        </LiquidFormLabel>
      )}
      <InputComponent
        id={name}
        size={size}
        error={!!(displayError && error)}
        value={value}
        onChange={(e) => {
          setLocalValue(e.target.value)
          onChange(e)
        }}
        {...otherProps}
      />
      {(!!(displayError && error && component === LiquidInput) || help) && (
        <LiquidFormHelpText id={`${name}-help-text`} error={Boolean(error)}>
          {error ? error : help ? help : null}
        </LiquidFormHelpText>
      )}
    </div>
  )
}

export default LiquidAutoSaveInput
